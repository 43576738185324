import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "response",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#response",
        "aria-label": "response permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Response`}</h1>
    <p>{`When a transaction is successful, the SDK will pass through the transaction details to the first parameter of the onSuccess hook.`}</p>
    <h6 {...{
      "id": "transaction-object-response-on-successroot-level-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h6" {...{
        "href": "#transaction-object-response-on-successroot-level-attributes",
        "aria-label": "transaction object response on successroot level attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transaction object response on Success(root level attributes):`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
_id: string
abn: string
amountBalance: number
amountBalanceString: string
amountCharged: number
amountChargedString: string
amountClaimsActualBenefit: number
amountClaimsActualBenefitString: string
amountClaimsExpectedBenefit: number
amountClaimsExpectedBenefitString: string
amountClaimsGap: number
amountClaimsGapString: string
amountFee: number
amountFeeNet: number
amountFeeNetString: string
amountFeeString: string
amountItemsCharged: number
amountItemsChargedString: string
amountItemsFee: number
amountItemsFeeString: string
amountOutOfPocket: number
amountOutOfPocketString: string
amountOutstanding: number
amountOutstandingString: string
amountRefund: number
amountRefundString: string
amountTax: number
amountTaxString: string
application: Object
businessId: string
businessStatus: string
claims: [
   amountActualBenefit: number
   amountActualBenefitString: string
   amountExpectedBenefit: number
   amountExpectedBenefitString: string
   amountGap: number
   amountGapString: string
   beneficiary: string
   claimDateString: string
   claimId: string
   claimItems: Object
   claimType: string
   createdByAccountId: string
   createdByAccountUsername: string
   funder: Object
   gatewayCode: string
   healthFund: Object
   healthFundAccount: Object
   professionalCategory: Object
   providerNumber: string
   requested: string
   responded: string
   specialties: Object
   specialty: Object
   status: string
   statusDescription: string
   statusHistory: Array
   _id: string
]
created: string
createdByAccountUsername: string
items: Array
modified: string
originatingFlow: string
patient: Object
payments: Array
practice: Object
staff: Object
transactionId: string
transactionType: string
webhooks: Array
}

`}</code></pre>
    <h5 {...{
      "id": "on-error-response-will-either-be-a-string-or-an-object",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#on-error-response-will-either-be-a-string-or-an-object",
        "aria-label": "on error response will either be a string or an object permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`On Error response will either be a string or an object.`}</h5>
    <h6 {...{
      "id": "transaction-object-response-onerror-object-root-level-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h6" {...{
        "href": "#transaction-object-response-onerror-object-root-level-attributes",
        "aria-label": "transaction object response onerror object root level attributes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transaction object response onError object (root level attributes):`}</h6>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "statusCode": number,
  "error": string,
  "message": string,
  "errorCode": number,
  "errors": [
    {
      "message": string,
      "attribute": string
    }
  ]
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      